/* eslint-disable max-len */

// This styles are imported in
// scout-dns/src/material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx

import iconsStyle from './icons';
import {
  defaultFont,
  secondaryColor,
} from '../style';

const style = {
  ...iconsStyle,
  'networksTab__policiesDropdown': {
    padding: '11px 0',
  },
  'cardActions__button': {
    padding: '0px 5px',
    marginLeft: '10px',
    height: '24px',
  },
  'cardActions__buttonNew_fullText': {
    '@media (min-width: 320px)': {
      display: 'none',
    },
    '@media (min-width: 480px)': {
      display: 'none',
    },
    '@media (min-width: 992px)': {
      display: 'none',
    },
    '@media (min-width: 1600px)': {
      display: 'inline-block',
    },
  },
  'cardActions__buttonNew_shortText': {
    'display': 'none',
    '@media (min-width: 320px)': {
      display: 'none',
    },
    '@media (min-width: 480px)': {
      display: 'inline-block',
    },
    '@media (min-width: 992px)': {
      display: 'inline-block',
    },
    '@media (min-width: 1600px)': {
      display: 'none',
    },
  },
  'cardActions__button_last': {
    padding: '0',
    marginLeft: '30px',
  },
  'cardActions__button_cancel': {
    paddingRight: '5px',
  },

  'cardActions__button_copy': {
    padding: '2px',
    margin: '2px',
  },

  'cardActions__button_delete': {
    padding: '0',
    marginLeft: '-5px',
  },
  'cardActions__button_back': {
    padding: '0',
    margin: '0px 10px 1px -4px',
  },
  'cardActions__button_close': {
    marginBottom: '-15px',
    marginRight: '15px',
  },
  'cardActions__button_print': {
    padding: '5px 10px',
  },
  'campusPageTabsPanel__tab': {
    color: '#FFF',
    height: '40px',
  },
  'campusPageTabsPanel__tab_active': {
    color: '#FFF',
    fontWeight: '600',
    boxShadow: '' +
      '0 14px 26px -12px rgba(0, 188, 212, 0.42),'
      + '0 4px 23px 0px rgba(0, 0, 0, 0.12),'
      + '0 8px 10px -5px rgba(0, 188, 212, 0.2)',
  },
  'clientEditForm__buttonShowHideDashboard': {
    ...defaultFont,
    fontSize: '17px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px 0',
    color: secondaryColor,
    borderBottom: `1px solid ${secondaryColor}`,
    padding: '0 0 6px 0',
  },
  'clientProfileSettingsTab__buttonShowHideAdvancedMode': {
    ...defaultFont,
    fontSize: '17px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px 0',
    color: secondaryColor,
    borderBottom: `1px solid ${secondaryColor}`,
    padding: '0 0 6px 0',
  },
  'filtersPanel__button_refresh': {
    marginLeft: '20px',
  },
  'filtersPanel__button_export': {
    marginLeft: '20px',
  },
  'buttonRefresh_text': {},
  'campusDashboard__buttonShowHideDashboard': {
    padding: 0,
    ...defaultFont,
    fontSize: '17px',
  },
  'clientDashboard__buttonShowHideDashboard': {
    ...defaultFont,
    padding: 0,
    fontSize: '17px',
    borderBottom: 0,
  },
  'universalDashboard__buttonShowHideDashboard': {
    ...defaultFont,
    padding: 0,
    fontSize: '17px',
    borderBottom: 0,
  },
  'operatorLoggingFiltersPanel__buttonShowHideAdditionalOptions': {
    padding: 0,
    ...defaultFont,
    fontSize: '17px',
  },
  'policyCategoriesTab__buttonCategory': {
    width: '100%',
    color: '#FFF',
  },
  'policySettingsTab__buttonShowHideAdvancedMode': {
    ...defaultFont,
    fontSize: '17px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px 0',
    color: secondaryColor,
    borderBottom: `1px solid ${secondaryColor}`,
    padding: '0 0 6px 0',
  },
  'policyTldTab__moveToPermitListButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '10px',
  },
  'policyTldTab__moveToBlockListButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    width: '40%',
  },
  'policyTldTab__selectAllButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    width: '150px',
  },
  'policyTldTab__deselectButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    width: '150px',
    marginLeft: '15px',
  },
  'subscriptionPage__button': {
    ...defaultFont,
    padding: '0 5px',
    fontSize: '15px',
    margin: '0 5px',
  },
  'unauthorizedForm__button': {
    margin: '7px 0',
  },
  'unauthorizedForm__microsoftSSOButton': {
    'margin': '7px 0 10px 0',
    'border': '1px solid #8c8c8c',
    'color': '#5e5e5e',
    'fontSize': '15px',
    'fontWeight': 600,
    'fontFamily': 'Segoe UI',
    'height': '41px',
    'padding': '0 12px 0 12px',
    '&:hover': {
      color: '#5e5e5e',
    },
  },
  'logOperatorFiltersPanel__showHideAdditionalFiltersButton': {
    padding: 0,
    ...defaultFont,
    fontSize: '17px',
  },
  'logTable__showHideLogFiltersButton': {
    padding: 0,
    ...defaultFont,
    fontSize: '17px',
    position: 'absolute',
    right: '15px',
  },
  'modalWindow__button': {
    fontSize: '13px',
    width: '70px',
  },
  'accountSwitcher__showHideAccountListButton': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    ...defaultFont,
    fontSize: '15px',
  },
  'accountSwitcher__showHideAccountListButton_header': {
    maxWidth: '175px',
    margin: '5px 15px 0 30px',
  },
  'accountSwitcher__showHideAccountListButton_sidebar': {
    margin: '10px auto',
    width: '80%',
  },
  'organizationSwitcher__showHideOrgListButton': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    ...defaultFont,
    fontSize: '15px',
  },
  'organizationSwitcher__showHideOrgListButton_header': {
    maxWidth: '175px',
    margin: '5px 15px 0 30px',
  },
  'organizationSwitcher__showHideOrgListButton_sidebar': {
    margin: '10px auto',
    width: '80%',
  },
  'revokeAccess': {
    marginTop: '20px',
    maxWidth: '300px',
    fontSize: '14px',
  },
  'pills-buttons': {
    fontSize: '12px',
    height: '28px',
    padding: '5px 10px',
  },
  'pills-buttons_clients': {
    fontSize: '12px',
    height: '28px',
    padding: '5px 10px',
    minWidth: '55px',
  },
  'pills-buttons_tld': {
    marginRight: '10px',
    marginBottom: '5px',
    minWidth: '100px',
  },
  'insights-filters-button': {
    'padding': 0,
    'transform': 'rotate(180deg)',
    '@media (max-width: 960px)': {
      transform: 'rotate(270deg)',
    },
  },
  'insights-filters-button_hide': {
    'transform': 'none',
    '@media (max-width: 960px)': {
      transform: 'rotate(90deg)',
    },
  },
  'insights-filters-actions-button': {
    ...defaultFont,
    textTransform: 'uppercase',
    fontWeight: 400,
    padding: '5px 30px',
    margin: '10px 5px',
  },
  'insights-filters-actions-button_hide': {
    display: 'none',
  },
  'clients-controls__button': {
    marginBottom: '5px',
    marginLeft: '10px',
  },
  'users-controls__button': {
    marginBottom: '5px',
    marginLeft: '10px',
  },
  'relay-table-button': {
    marginBottom: '5px',
  },
  'relay-edit-form-button': {
    margin: '5px 0 5px 14px',
  },

  'selectableEntityListElement__deselectButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    width: '120px',
    marginLeft: '15px',
    marginRight: '10px',
  },

  'selectableEntityListElement__selectAllButton': {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: '400',
    width: '120px',
    marginLeft: '10px',
    marginRight: '15px',
  },

  'usage-billing-settings__button': {
    ...defaultFont,
    textTransform: 'uppercase',
    fontWeight: 400,
    padding: '5px 30px',
    margin: '10px 5px',
  },

  'editSSOForm__showHideLegacyButton': {
    ...defaultFont,
    padding: 0,
    fontSize: '17px',
    borderBottom: 0,
  },
};

export default style;
