export const types = {
  SET_SINGLE_SIGN_ON_CONFIGURATIONS: 'SET_SINGLE_SIGN_ON_CONFIGURATIONS',
  CHANGE_EDIT_MODE_AND_SINGLE_SIGN_ON_STATE: 'CHANGE_EDIT_MODE_AND_SINGLE_SIGN_ON_STATE',
  SET_SELECTED_SINGLE_SIGN_ON_CONFIGURATION: 'SET_SELECTED_SINGLE_SIGN_ON_CONFIGURATION',
};

export const DEFAULT_SSO_ROLES = {
  ADMIN: 'ScoutDNS_Admin',
  SERVICE_DESK: 'ScoutDNS_ServiceDesk',
  VIEWER: 'ScoutDNS_Viewer',
};
