import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {EventType, PublicClientApplication} from '@azure/msal-browser';

import {withStyles} from '@material-ui/core/styles';

import {
  CustomButton,
} from '../../material-dashboard-pro-react/components/index';

import {base64ToBytes} from '../../utils/base64';
import {loginRequest, msalConfig} from '../../utils/authConfig';

import {RenderOrEmpty} from '..';

import {
  MicrosoftIcon,
} from '../../icons/index';

import style from './style';

class LoginFormSSO extends Component {
  textDecoder = new TextDecoder();

  msalInstance;

  msalInteractionStatus;

  defaultClientIdPart = "multitenant";

  componentDidMount() {
    this.msalInteractionStatus = sessionStorage.getItem('msal.interaction.status');
    this.msalInstance = new PublicClientApplication(msalConfig(this.msalInteractionStatus));
    this.msalInstance.initialize().then(() => this.msalInstance.handleRedirectPromise());

    this.msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS: {
          this.props.loginWithSSO(message.payload.idTokenClaims.aud, this.props.history);
          return;
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          this.props.loginWithSSO(message.payload.idTokenClaims.aud, this.props.history);
          break;
        }
        default: {
          // nothing
        }
      }
    });
  }

  loginThroughSSO = async() => {
    const pathParts = this.props.history.location.pathname.split('/');
    const clientIdPart = pathParts[pathParts.length - 1];
    const clientId = (clientIdPart === this.defaultClientIdPart)
      ? process.env.REACT_APP_AZURE_CLIENT_ID
      : this.textDecoder.decode(base64ToBytes(clientIdPart));

    const msalConfiguration = msalConfig(clientId);

    this.msalInstance = new PublicClientApplication(msalConfiguration);
    await this.msalInstance.initialize();
    await this.msalInstance.handleRedirectPromise();

    const accounts = this.msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      await this.msalInstance.loginRedirect(loginRequest);
    }
  };

  render() {
    const {
      classes,
      isLoggedInFailed,
    } = this.props;

    return (
      <>
        <div className={classes.loginFormSSO__ssoContainer}>
          <CustomButton
            color="white"
            customClasses="unauthorizedForm__microsoftSSOButton"
            fullWidth={true}
            onClick={this.loginThroughSSO}
          >
            <div className={classes.unauthorizedForm__microsoftSSOIconContainer}>
              <MicrosoftIcon />
              {I18n.t('loginPageSSO.microsoftSSO')}
            </div>
          </CustomButton>
        </div>
        <RenderOrEmpty isShow={isLoggedInFailed}>
          <div className={classes.loginFormSSO__errorMessage}>
            {I18n.t('loginPageSSO.errorMessageLoginFailed')}
          </div>
        </RenderOrEmpty>
      </>
    );
  }
}

LoginFormSSO.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loginWithSSO: PropTypes.func.isRequired,
  isLoggedInFailed: PropTypes.bool.isRequired,
};

LoginFormSSO.defaultProps = {};

export default withRouter(withStyles(style)(LoginFormSSO));
