import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import {
  createSingleSignOnConfiguration,
  changeEditModeAndSingleSignOnState,
  deleteSingleSignOnConfiguration,
  updateSingleSignOnConfiguration,
  updateSingleSignOnConfigurationData,
} from './action';

import SingleSignOnCreating from '../../components/SingleSignOnCreating/SingleSignOnCreating';
import SingleSignOnDetails from '../../components/SingleSignOnDetails/SingleSignOnDetails';

import {MODES_SHOWING_CONTAINER_WITH_FORM, STATES_ENTITY} from '../../constants';

import {msalConfig} from '../../utils/authConfig';

import {bytesToBase64} from '../../utils/base64';

class SingleSignOn extends React.Component {
  static initialLocalState = {
    modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_FORM.SHOW_CREATING_FORM_FULL_SCREEN,
    loginURL: msalConfig().auth.redirectUri,
  };

  static wasDataScopeSwitched(prevProps, props) {
    return !_isEqual(prevProps.loggedAccount.accountId, props.loggedAccount.accountId)
      || !_isEqual(prevProps.currentOrganization, props.currentOrganization);
  }

  static shouldUpdateSingleSignOnData(prevProps, props) {
    return !_isEqual(prevProps.singleSignOnConfigurations, props.singleSignOnConfigurations)
      || !_isEqual(prevProps.singleSignOnConfiguration, props.singleSignOnConfiguration);
  }

  textEncoder = new TextEncoder();

  constructor(props) {
    super(props);
    this.state = SingleSignOn.initialLocalState;
  }

  componentDidMount() {
    this.props.updateSingleSignOnConfigurationData();
    if (this.props.singleSignOnConfigurations.length) this.showSingleSignOnConfigurationDetails();
    else this.showFormCreatingSingleSignOnConfiguration();
  }

  componentDidUpdate(prevProps) {
    if (SingleSignOn.wasDataScopeSwitched(prevProps, this.props)) {
      this.resetLocalState();
      this.props.updateSingleSignOnConfigurationData();
    }
    if (SingleSignOn.shouldUpdateSingleSignOnData(prevProps, this.props)) {
      if (this.props.singleSignOnConfigurations.length) this.showSingleSignOnConfigurationDetails();
      else this.showFormCreatingSingleSignOnConfiguration();
    }
  }

  resetLocalState = () => this.setState(SingleSignOn.initialLocalState);

  showFormCreatingSingleSignOnConfiguration = () => {
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_FORM.SHOW_CREATING_FORM_FULL_SCREEN,
    });
    this.props.changeEditModeAndSingleSignOnState(true, STATES_ENTITY.CREATING);
  };

  showSingleSignOnConfigurationDetails = () => {
    const clientId = this.props.singleSignOnConfiguration.clientId;
    this.setState({
      modeShowingContainer: MODES_SHOWING_CONTAINER_WITH_FORM.SHOW_ENTITY_DETAILS_FULL_SCREEN,
      loginURL: clientId && msalConfig().auth.redirectUri
        + bytesToBase64(this.textEncoder.encode(clientId)),
    });
    this.props.changeEditModeAndSingleSignOnState(false, STATES_ENTITY.EDITING);
  };

  handleClickButtonSaveNewSingleSignOnConfiguration = () => {
    const {newOrUpdatedSingleSignOnConfiguration} = this.props;

    this.props.createSingleSignOnConfiguration(newOrUpdatedSingleSignOnConfiguration)
      .then((value) => {
        if (value) this.showSingleSignOnConfigurationDetails();
      });
  };

  handleClickButtonCancelCreateSingleSignOnConfiguration = () => {
    this.props.changeEditModeAndSingleSignOnState(false, STATES_ENTITY.EDITING_CANCELED);
  };

  handleClickButtonEditSingleSignOnConfiguration = () => {
    this.props.changeEditModeAndSingleSignOnState(true, STATES_ENTITY.EDITING);
  };

  handleClickButtonDeleteSingleSignOnConfiguration = () => {
    const {singleSignOnConfiguration} = this.props;

    this.props.deleteSingleSignOnConfiguration(singleSignOnConfiguration.id);
  };

  handleClickButtonSaveUpdatedSingleSignOnConfiguration = () => {
    const {newOrUpdatedSingleSignOnConfiguration} = this.props;

    this.props.updateSingleSignOnConfiguration(newOrUpdatedSingleSignOnConfiguration);
    this.showSingleSignOnConfigurationDetails();
  };

  getContainerItemByModeShowingContainer = (modeShowingContainer) => {
    const {
      isValidSingleSignOnConfiguration,
      isEditMode,
    } = this.props;

    let containerItem = null;
    switch (modeShowingContainer) {
      case MODES_SHOWING_CONTAINER_WITH_FORM.SHOW_CREATING_FORM_FULL_SCREEN:
        containerItem = (
          <SingleSignOnCreating
                handleClickButtonSave={this.handleClickButtonSaveNewSingleSignOnConfiguration}
                isValidSingleSignOnConfiguration={isValidSingleSignOnConfiguration}
                title={I18n.t('configureSingleSignOnPage.createTitle')}
          />
        );
        break;
      case MODES_SHOWING_CONTAINER_WITH_FORM.SHOW_ENTITY_DETAILS_FULL_SCREEN:
        containerItem = (
          <SingleSignOnDetails
            handleClickButtonCancel={this.handleClickButtonCancelCreateSingleSignOnConfiguration}
            handleClickButtonEdit={this.handleClickButtonEditSingleSignOnConfiguration}
            handleClickButtonSave={this.handleClickButtonSaveUpdatedSingleSignOnConfiguration}
            handleClickButtonDelete={this.handleClickButtonDeleteSingleSignOnConfiguration}
            isValidSingleSignOnConfiguration={isValidSingleSignOnConfiguration}
            isEditMode={isEditMode}
            title={I18n.t('configureSingleSignOnPage.detailsTitle')}
            legacyLoginURL={this.state.loginURL}
          />
        );
        break;
      default:
        break;
    }
    return containerItem;
  };

  render() {
    const {
      modeShowingContainer,
    } = this.state;

    return (
      this.getContainerItemByModeShowingContainer(modeShowingContainer)
    );
  }
}

SingleSignOn.propTypes = {
  newOrUpdatedSingleSignOnConfiguration: PropTypes.object.isRequired,
  isValidSingleSignOnConfiguration: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loggedAccount: PropTypes.object.isRequired,
  singleSignOnConfigurations: PropTypes.array.isRequired,
  singleSignOnConfiguration: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentOrganization: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,

  createSingleSignOnConfiguration: PropTypes.func.isRequired,
  changeEditModeAndSingleSignOnState: PropTypes.func.isRequired,
  deleteSingleSignOnConfiguration: PropTypes.func.isRequired,
  updateSingleSignOnConfiguration: PropTypes.func.isRequired,
  updateSingleSignOnConfigurationData: PropTypes.func.isRequired,
};

SingleSignOn.defaultProps = {
  currentOrganization: null,
};

const mapStateToProps = (state) => ({
  loggedAccount: state.userAccountsReducer.loggedAccount,
  currentOrganization: state.userOrganizationsReducer.currentOrganization,

  newOrUpdatedSingleSignOnConfiguration:
    state.singleSignOnEditFormReducer.editableSingleSignOnConfiguration,
  isValidSingleSignOnConfiguration:
    state.singleSignOnEditFormReducer.isValidSingleSignOnConfiguration,
  singleSignOnConfigurations: state.singleSignOnReducer.singleSignOnConfigurations,
  singleSignOnConfiguration: state.singleSignOnReducer.selectedSingleSignOnConfiguration,
  isEditMode: state.singleSignOnReducer.isEditMode,
});

const mapDispatchToProps = (dispatch) => ({
  createSingleSignOnConfiguration: bindActionCreators(createSingleSignOnConfiguration, dispatch),
  changeEditModeAndSingleSignOnState:
    bindActionCreators(changeEditModeAndSingleSignOnState, dispatch),
  deleteSingleSignOnConfiguration: bindActionCreators(deleteSingleSignOnConfiguration, dispatch),
  updateSingleSignOnConfiguration: bindActionCreators(updateSingleSignOnConfiguration, dispatch),
  updateSingleSignOnConfigurationData:
    bindActionCreators(updateSingleSignOnConfigurationData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
