import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {LoginForm, LoginForm2FA, RenderOrEmpty} from '../../components';

import {login, login2FA, loginWithSSO, resendCode} from '../../actions/auth/login';

function Login(props) {
  const {
    auth,
    auth2FA,
    authWithSSO,
    // eslint-disable-next-line no-shadow
    resendCode,
    isLoggedInFailed,
    isSecondFactorFailed,
    show2FAForm,
  } = props;
  const checkAuthCode = (data, historyParam) => auth2FA(data, historyParam, true);

  return (
    <>
      <RenderOrEmpty isShow={!show2FAForm}>
        <LoginForm
          login={auth}
          loginWithSSO={authWithSSO}
          isLoggedInFailed={isLoggedInFailed}
        />
      </RenderOrEmpty>
      <RenderOrEmpty isShow={show2FAForm}>
        <LoginForm2FA
          login2FA={checkAuthCode}
          resendCode={resendCode}
          isSecondFactorFailed={isSecondFactorFailed}
        />
      </RenderOrEmpty>
    </>
  );
}

Login.propTypes = {
  auth: PropTypes.func.isRequired,
  auth2FA: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  isLoggedInFailed: PropTypes.bool.isRequired,
  isSecondFactorFailed: PropTypes.bool.isRequired,
  show2FAForm: PropTypes.bool,
};

Login.defaultProps = {
  show2FAForm: false,
};

const mapStateToProps = (state) => ({
  isLoggedInFailed: state.authReducer.isLoggedInFailed,
  isSecondFactorFailed: state.authReducer.isSecondFactorFailed,
  show2FAForm: state.authReducer.show2FAForm,
});

const mapDispatchToProps = (dispatch) => ({
  auth: bindActionCreators(login, dispatch),
  auth2FA: bindActionCreators(login2FA, dispatch),
  authWithSSO: bindActionCreators(loginWithSSO, dispatch),
  resendCode: bindActionCreators(resendCode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
