import {LogLevel} from '@azure/msal-browser';

export const msalConfig = (clientId, redirectUri = process.env.REACT_APP_AZURE_REDIRECT_URI) => ({
  auth: {
    clientId: clientId,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
            break;
          default:
            // no actions
        }
      },
    },
  },
});

export const loginRequest = {
  scopes: ['User.Read', 'openid', 'profile', 'email'],
};
