import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {EventType, PublicClientApplication} from '@azure/msal-browser';

import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  CustomButton,
  CustomInput,
} from '../../material-dashboard-pro-react/components/index';

import {loginRequest, msalConfig} from '../../utils/authConfig';

import {
  RenderOrEmpty,
  SectionLinkInUnauthForm,
} from '../index';

import {
  ROUTES,
} from '../../constants';

import {
  MicrosoftIcon,
  Visibility,
  VisibilityOff,
} from '../../icons/index';

import style from './style';

class LoginForm extends Component {
  msalInstance;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
      submitDisabled: true,
    };
  }

  componentDidMount() {
    const config = msalConfig(
      process.env.REACT_APP_AZURE_CLIENT_ID,
      process.env.REACT_APP_AZURE_UNIVERSAL_SSO_REDIRECT_URI,
    );
    this.msalInstance = new PublicClientApplication(config);
    this.msalInstance.initialize().then(() => this.msalInstance.handleRedirectPromise());

    this.msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS: {
          this.props.loginWithSSO(message.payload.idTokenClaims.aud, this.props.history);
          return;
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          this.props.loginWithSSO(message.payload.idTokenClaims.aud, this.props.history);
          break;
        }
        default: {
          // nothing
        }
      }
    });
  }

  onChange = (e) => {
    const newState = {
      ...this.state,
      [e.target.name]: e.target.value,
    };
    const isValid = this.validate(newState);
    this.setState({
      ...newState,
      submitDisabled: !isValid,
    });
  };

  onSubmit = () => {
    const {
      password,
      username,
    } = this.state;
    this.props.login(
      {
        name: username.toLowerCase(),
        password: password,
      },
      this.props.history,
    );
  };

  validate = (data) => {
    const isNotEmpty = (str) => !!str;
    const validatingRules = [
      {
        name: 'username',
        validatingRules: [isNotEmpty],
      },
      {
        name: 'password',
        validatingRules: [isNotEmpty],
      },
    ];

    return validatingRules.reduce(
      (result, rule) => {
        const value = data[rule.name];
        return result && rule.validatingRules.reduce(
          (_result, func) => _result && func(value),
          true,
        );
      },
      true,
    );
  };

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  showHidePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  loginWithSSO = async() => {
    const accounts = this.msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      await this.msalInstance.loginRedirect(loginRequest);
    }
  };

  render() {
    const {
      password,
      showPassword,
      submitDisabled,
      username,
    } = this.state;

    const {
      classes,
      isLoggedInFailed,
    } = this.props;

    const propsInput = {
      username: {
        value: username,
        name: 'username',
        onChange: this.onChange,
        type: 'text',
        onBlur: () => this.setState((prevState) => ({username: prevState.username.trim()})),
      },
      password: {
        value: password,
        name: 'password',
        onChange: this.onChange,
        type: showPassword ? 'text' : 'password',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={this.showHidePassword}
              onMouseDown={this.handleMouseDownPassword}
            >
              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        onBlur: () => this.setState((prevState) => ({password: prevState.password.trim()})),
      },
    };
    const propsFormControl = {
      fullWidth: true,
    };

    return (
      <>
        <RenderOrEmpty isShow={isLoggedInFailed}>
          <div className={classes.loginForm__errorMessage}>
            {I18n.t('loginPage.errorMessageLoginFailed')}
          </div>
        </RenderOrEmpty>
        <CustomInput
          labelText={I18n.t('loginPage.username')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.username}
        />
        <CustomInput
          labelText={I18n.t('loginPage.password')}
          id={uuid()}
          formControlProps={propsFormControl}
          inputProps={propsInput.password}
        />
        <SectionLinkInUnauthForm
          text={I18n.t('loginPage.forgotPassword')}
          to={ROUTES.RESET_PASSWORD}
        />
        <CustomButton
          fullWidth={true}
          disabled={submitDisabled}
          color="secondary"
          customClasses="unauthorizedForm__button"
          onClick={this.onSubmit}
        >
          {I18n.t('loginPage.login')}
        </CustomButton>
        <p className={classes.loginForm__or}>
          {I18n.t('loginPage.or')}
        </p>
        <CustomButton
          fullWidth={true}
          color="white"
          customClasses="unauthorizedForm__microsoftSSOButton"
          onClick={this.loginWithSSO}
        >
          <div className={classes.unauthorizedForm__microsoftSSOIconContainer}>
            <MicrosoftIcon />
            {I18n.t('loginPage.loginWithMicrosoft')}
          </div>
        </CustomButton>
        <p>
          By logging in you agree to the ‎
          <a href="https://www.scoutdns.com/terms-and-conditions/">
            Terms and Conditions.
          </a>
        </p>
      </>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  loginWithSSO: PropTypes.func.isRequired,
  isLoggedInFailed: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {};

export default withRouter(withStyles(style)(LoginForm));
