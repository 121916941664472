import _cloneDeep from 'lodash/cloneDeep';
import {DEFAULT_SSO_ROLES} from './constants';

const listWithDefaultFixedRole = (rolesList, roleName) => {
  const fixedRoleIndex = rolesList.findIndex((r) => r === roleName || r.value === roleName);
  if (fixedRoleIndex >= 0) {
    rolesList[fixedRoleIndex] = {
      value: roleName,
      isFixed: true,
    };
    return rolesList;
  }
  return [{value: roleName}, ...rolesList];
};

export function getSingleSignOnConfigurationToCreate(newSingleSignOnConfiguration) {
  const data = _cloneDeep(newSingleSignOnConfiguration);

  data.selectedProviderAdminRoles =
    listWithDefaultFixedRole(data.selectedProviderAdminRoles, DEFAULT_SSO_ROLES.ADMIN)
      .map((role) => role.value);
  data.selectedProviderViewerRoles =
    listWithDefaultFixedRole(data.selectedProviderViewerRoles, DEFAULT_SSO_ROLES.VIEWER)
      .map((role) => role.value);
  data.selectedProviderServiceDeskRoles =
    listWithDefaultFixedRole(data.selectedProviderServiceDeskRoles, DEFAULT_SSO_ROLES.SERVICE_DESK)
      .map((role) => role.value);

  return data;
}

export function getSingleSignOnConfigurationToUpdate(newSingleSignOnConfiguration) {
  const data = _cloneDeep(newSingleSignOnConfiguration);

  data.selectedProviderAdminRoles =
    listWithDefaultFixedRole(data.selectedProviderAdminRoles, DEFAULT_SSO_ROLES.ADMIN)
      .map((role) => role.value ?? role);
  data.selectedProviderViewerRoles =
    listWithDefaultFixedRole(data.selectedProviderViewerRoles, DEFAULT_SSO_ROLES.VIEWER)
      .map((role) => role.value ?? role);
  data.selectedProviderServiceDeskRoles =
    listWithDefaultFixedRole(data.selectedProviderServiceDeskRoles, DEFAULT_SSO_ROLES.SERVICE_DESK)
      .map((role) => role.value ?? role);

  return data;
}
