import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core/styles';

import {EntityEditingCard} from '../index';

import {SingleSignOnEditForm} from '../../containers/index';

import {ACCESS_RIGHTS} from '../../constants';

import style from './style';

const rightAvailabilityMap = {
  buttonDelete: [ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_DELETE],
  buttonEdit: [ACCESS_RIGHTS.SINGLE_SIGN_ON_CONFIGURATION_EDIT],
};

function SingleSignOnDetails(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonEdit,
    handleClickButtonSave,
    handleClickButtonDelete,
    isValidSingleSignOnConfiguration,
    isEditMode,
    title,
    legacyLoginURL,
  } = props;

  return (
    <EntityEditingCard
      title={title}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonSave={handleClickButtonSave}
      handleClickButtonDelete={handleClickButtonDelete}
      isValidEntity={isValidSingleSignOnConfiguration}
      isEditMode={isEditMode}
      isThereEditBlock={true}
      deleteModalWindowText={I18n.t('modalWindow.deleteSingleSignOnConfiguration')}
      deleteButtonText={I18n.t('configureSingleSignOnPage.buttons.deleteSingleSignOn')}
      editButtonText={I18n.t('configureSingleSignOnPage.buttons.editSingleSignOn')}
      isThereDeleteBlock={true}
      accessRights={rightAvailabilityMap}
      customCardContentClass="card__content_block-page"
      content={(
        <SingleSignOnEditForm isEditForm={true} legacyLoginURL={legacyLoginURL} />
      )}
    />
  );
}

SingleSignOnDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isValidSingleSignOnConfiguration: PropTypes.bool.isRequired,
  legacyLoginURL: PropTypes.string.isRequired,
};

export default withStyles(style)(SingleSignOnDetails);
