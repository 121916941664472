/* eslint-disable max-len */
import customInputStyle from '../../material-dashboard-pro-react/assets/jss/material-dashboard-pro-react/components/customInputStyle';

const style = {
  loginForm__errorMessage: {
    ...customInputStyle.labelRoot,
    ...customInputStyle.labelRootError,
    fontSize: '12px',
    textAlign: 'center',
  },
  loginForm__ssoContainer: {
    marginTop: '50px',
  },
  loginForm__or: {
    margin: '0.5em 0 0 0',
    textAlign: 'center',
  },
  unauthorizedForm__microsoftSSOIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
};

export default style;
